<template>
  <el-main>
    <el-form class="el-form-search" label-width="100px">
      <el-form-item label="手机号：">
        <el-input size="small" v-model="mobile"></el-input>
      </el-form-item>
      <el-form-item label="审核状态：">
        <el-select v-model="status" size="small" filterable placeholder="请选择">
          <el-option label="全部" value=""></el-option>
          <el-option label="待支付" :value="-1"></el-option>
          <el-option label="待审核" :value="1"></el-option>
          <el-option label="审核通过" :value="2"></el-option>
          <el-option label="审核驳回" :value="3"></el-option>
          <el-option label="已清退" :value="4"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="申请日期：">
        <el-date-picker @change="getDate" v-model="date" size="small" type="daterange" range-separator="~" start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
      </el-form-item>
      <el-form-item label=" " label-width="25px">
        <el-button size="small" @click="getList" type="primary">搜索</el-button>
        <el-button size="small" @click="searchClear" type="text">清除搜索条件</el-button>
      </el-form-item>
    </el-form>
    <el-table :data="list" :header-cell-style="{ 'background-color': '#F8F9FA' }">
      <template slot="empty">
        <No />
      </template>
      <el-table-column prop="nickname" label="用户昵称">
        <template v-slot="{ row }">
          <div class="userInfo">
            <el-image :src="row.avatar"></el-image>
            <span>{{ row.nickname }}</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="mobile" label="手机号" align="center"></el-table-column>
      <el-table-column prop="from_icon" label="来源" align="center">
        <template v-slot="{ row }">
          <el-image :src="row.from_icon"></el-image>
        </template>
      </el-table-column>
      <el-table-column prop="status" label="状态" align="center">
        <template v-slot="{ row }">
          {{ row.status == 0 ? '待审核' : row.status == 1 ? '审核通过' : row.status == 2 ? '审核驳回' : row.status == 3 ? '已清退' : '待支付' }}
        </template>
      </el-table-column>
      <el-table-column prop="amount" label="付款金额" align="center"></el-table-column>
      <el-table-column prop="date" label="提交时间" align="center"></el-table-column>
      <el-table-column label="操作" width="240" align="center" fixed="right">
        <template v-slot="{ row }">
          <el-button @click="see(row)" type="text" size="small">查看</el-button>
          <el-button v-if="!row.status" @click="agree(row)" type="text" size="small">通过</el-button>
          <el-button v-if="!row.status" @click="refuse(row)" type="text" size="small">驳回</el-button>
          <el-button @click="seeDetail(row)" type="text" size="small">审核详情</el-button>
        </template>
      </el-table-column>
    </el-table>
    <Paging :total="total_number" :page="page" :pageNum="rows" @updatePageNum="updateData"></Paging>
    <el-dialog title="查看" :visible.sync="dislogInfo" width="600px">
      <el-form>
        <el-form-item style="margin: 0" v-for="(item, index) in infoList" :key="index" :label="item.name + '：'">
          <div class="img-list" v-if="Array.isArray(item.value)">
            <el-image :src="img" :preview-src-list="[item.value]" v-for="(img, index) in item.value" :key="index"></el-image>
          </div>
          <div v-else>
            <!-- 5 6 天之福定制设置-->
            <el-image v-if="item.type == 5 || item.type == 6" style="width: 50px; height: 50px" :preview-src-list="[item.value]" :src="item.value"></el-image>
            <span v-else>{{ item.value }}</span>
          </div>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dislogInfo = !1">取 消</el-button>
        <el-button type="primary" @click="dislogInfo = !1">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="审核详情" :visible.sync="dislogDetail" width="700px">
      <el-table :data="auditLogList" :header-cell-style="{ 'background-color': '#F8F9FA' }">
        <template slot="empty">
          <No />
        </template>
        <el-table-column prop="date" label="审核时间" width="220" align="center"></el-table-column>
        <el-table-column prop="u_name" label="审核人员" align="center"></el-table-column>
        <el-table-column prop="status" label="审核结果" align="center">
          <template slot-scope="scope">
            {{ scope.row.status == 1 ? '通过' : '不通过' }}
          </template>
        </el-table-column>
        <el-table-column prop="content" label="反馈详情" align="center"></el-table-column>
      </el-table>
      <div style="text-align: right; margin-top: 15px">
        <el-pagination background layout="prev, pager, next" @current-change="handleLogCurrentChange" :page-size="rows1" :current-page="page1" :total="total_number1"></el-pagination>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dislogDetail = !1">取 消</el-button>
        <el-button type="primary" @click="dislogDetail = !1">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="审核" :visible.sync="dislogaudit" width="600px">
      <el-form>
        <el-form-item style="margin: 0" label="请选择审核结果："></el-form-item>
        <el-form-item style="margin: 0">
          <el-radio-group v-model="auditFom.status">
            <el-radio :label="1">通过</el-radio>
            <el-radio :label="2">驳回</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item v-if="auditFom.status == 2" label="请填写驳回原因：">
          <el-input type="textarea" :rows="5" placeholder="请输入内容" v-model="auditFom.content">></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dislogaudit = !1">取 消</el-button>
        <el-button type="primary" @click="handleAudit">确 定</el-button>
      </span>
    </el-dialog>
  </el-main>
</template>

<script>
import { getDateformat } from '@/util/getDate';
import wx from '@/assets/image/wx.png';
import ali from '@/assets/image/ali.png';
import douyin from '@/assets/image/douyin.png';
import baidu from '@/assets/image/baidu.png';
import qq from '@/assets/image/qq.png';
import Paging from '@/components/paging';
export default {
  components: {
    Paging,
  },
  data() {
    return {
      page: 1,
      rows: 10,
      total_number: 0,
      mobile: '',
      status: '',
      date: '',
      start_time: '',
      end_time: '',
      list: [],
      batchActionId: 0,
      dislogInfo: !1,
      dislogDetail: !1,
      dislogaudit: !1,
      infoList: [],
      auditLogList: [],
      page1: 1,
      rows1: 10,
      total_number1: 0,
      currentInfo: {},
      id: '',
      auditFom: {
        status: 1,
        content: '',
      },
    };
  },
  created() {
    this.getList();
  },
  methods: {
    agree(row) {
      this.auditFom.status = 1;
      this.dislogaudit = !0;
      this.id = row.id;
    },
    refuse(row) {
      this.auditFom.status = 2;
      this.dislogaudit = !0;
      this.id = row.id;
    },
    searchClear() {
      this.status = '';
      this.mobile = '';
      this.start_time = '';
      this.end_time = '';
      this.getList();
    },
    updateData(val, status) {
      if (status == 0) {
        this.rows = val;
      } else {
        this.page = val;
      }
      this.getList();
    },
    handleAudit() {
      if (this.auditFom.status == 1) this.auditFom.content = '';
      this.$axios
        .post(this.$api.distribution.auditEditStatus, {
          id: this.id,
          content: this.auditFom.content,
          status: this.auditFom.status,
        })
        .then(res => {
          if (res.code == 0) {
            this.$message.success('处理成功');
            this.getList();
            this.dislogaudit = !1;
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    getDate(val) {
      if (val.length) {
        this.start_time = val[0].getTime() / 1000;
        this.end_time = val[1].getTime() / 1000;
        if (this.start_time == this.end_time) this.end_time = this.end_time + 86400;
      }
    },
    getList() {
      this.$axios
        .post(this.$api.distribution.auditList, {
          page: this.page,
          rows: this.rows,
          mobile: this.mobile,
          status: this.status,
          start_time: this.start_time,
          end_time: this.end_time,
        })
        .then(res => {
          if (res.code == 0) {
            let list = res.result.list;
            list.map(item => {
              item.date = getDateformat(item.create_time);
              item.is_select = !1;
              if (item.comefrom == 1) item.from_icon = wx;
              if (item.comefrom == 2) item.from_icon = ali;
              if (item.comefrom == 4) item.from_icon = douyin;
              if (item.comefrom == 5) item.from_icon = qq;
              if (item.comefrom == 6) item.from_icon = baidu;
            });
            this.list = list;
            this.total_number = res.result.total_number;
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    see(row) {
      this.$axios
        .post(this.$api.distribution.auditInfo, {
          id: row.id,
        })
        .then(res => {
          if (res.code == 0) {
            let list = res.result;
            list.unshift({
              name: '用户昵称',
              value: row.nickname,
            });
            //天之福定制设置
            list.map(item => {
              if (item.type == 3 && item.name == '工作地址') {
                let value = '';
                item.value.map(val => (value += val));
                item.value = value;
              }
            });
            this.dislogInfo = !0;
            this.infoList = list;
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    seeDetail(row) {
      this.$axios
        .post(this.$api.distribution.auditLogList, {
          id: row.id,
          page: this.page1,
          rows: this.rows1,
        })
        .then(res => {
          if (res.code == 0) {
            res.result.list.map(item => (item.date = getDateformat(item.create_time)));
            this.dislogDetail = !0;
            this.auditLogList = res.result.list;
            this.currentInfo = row;
            this.total_number1 = res.result.total_number;
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    handleLogCurrentChange(val) {
      this.page1 = val;
      this.seeDetail(this.currentInfo);
    },
  },
};
</script>

<style lang="less" scoped>
.el-main {
  background: #fff;
  .el-table {
    .userInfo {
      display: flex;
      align-items: center;
      .el-image {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        margin-right: 10px;
      }
    }
  }
  .img-list {
    display: flex;
    .el-image {
      margin-right: 15px;
      width: 100px;
      height: 70px;
    }
  }
}
</style>
